import "./bootstrap";
import "../css/app.css";

import { createApp, DefineComponent, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy";
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || "Artwise Reporting";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>("./Pages/**/*.vue")),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue);

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) || 0.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            //replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 0,
        });

        app.mount(el);
        return app;
    },
    progress: {
        color: "#20419A",
    },
});
